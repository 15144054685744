
































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { CarouselItem } from '@/types/cms/components/';
import { DataTableHeader } from 'vuetify/types/';
import { CloneHelper } from '@/utils/clonehelper';
import { getFileObjectByUrl } from '@/utils/filehelper';

/**
 * CMSAdminComponentCarouselItemList component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        'cms-admin-component-carousel-item-form-dialog': () => import(
            './CMSAdminComponentCarouselItemFormDialog.vue'
        )
    }
})
export default class CMSAdminComponentCarouselItemList extends Vue {
    // Prop of type boolean that defined whether the items are editable or not
    @Prop(Boolean) readonly editable: boolean | undefined;

    // array with all lists
    @Prop({ type: Array as () => Array<CarouselItem> }) readonly carouselItems: Array<CarouselItem> | undefined;

    // search text
    private searchText = '';

    // the item of the new/edited list
    private editedCarouselItem: CarouselItem = {
        id: -1,
        image: {
            id: -1,
            src: '',
            obj: undefined
        },
        content: ''
    };

    // Default list item values
    private defaultCarouselItem: CarouselItem = {
        id: -1,
        image: {
            id: -1,
            src: '',
            obj: undefined
        },
        content: ''
    }

    // The index of the edited list (-1 = new list)
    private editedCarouselItemIndex = -1;

    // Boolean that defines, whether the FormDialog will be displayed or not
    private showFormDialog = false;

    // Mode of the current form (CRUD string)
    private formDialogMode = 'create';

    /**
     * @returns Array with all table headers
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    get tableHeaders(): Array<DataTableHeader> {
        const headers: Array<DataTableHeader> = [
            {
                text: this.$t('table.headers.id').toString(),
                value: 'id'
            },
            {
                text: this.$t('table.headers.content').toString(),
                value: 'content'
            }
        ];

        if (this.editable) {
            headers.push({
                text: this.$t('table.headers.actions').toString(),
                value: 'actions'
            });
        }
        
        return headers;
    }

    /**
     * Opens the CarouselItemFormDialog to create new item
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private addCarouselItem() {
        // get max id
        const carouselItemId = (this.carouselItems && this.carouselItems.length >= 1) ? this.carouselItems[this.carouselItems.length -1].id +1 : 0;
        const imageId = (this.carouselItems && this.carouselItems.length >= 1) ? this.carouselItems[this.carouselItems.length -1].image.id +1 : 0;

        this.editedCarouselItem = CloneHelper.deepCloneObject({
            ...this.defaultCarouselItem, 
            id: carouselItemId,
            image: {
                ...this.defaultCarouselItem.image,
                id: imageId
            }
        });
        this.editedCarouselItemIndex = -1;
        this.formDialogMode = 'create';
        this.showFormDialog = true;
    }

    /**
     * Opens the CarouselItemFormDialog to edit the given item
     * 
     * @param carouselItem carousel item
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async editCarouselItem(carouselItem: CarouselItem) {
        if (!this.carouselItems) {
            return;
        }

        this.editedCarouselItem = CloneHelper.deepCloneObject({ 
            ...carouselItem,
            image: {
                ...carouselItem.image,
                obj: undefined
            }
        });

        if (carouselItem.image.obj) {
            const imageUrl = process.env.VUE_APP_API_URL + carouselItem.image.src;
            const imageObj = await getFileObjectByUrl(imageUrl, carouselItem.content);

            this.editedCarouselItem.image.obj = imageObj;
        }

        this.editedCarouselItemIndex = this.carouselItems.indexOf(carouselItem);
        this.formDialogMode = 'update';
        this.showFormDialog = true;
    }

    /**
     * Deletes the given item
     * 
     * @param carouselItem carousel item
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async deleteCarouselItem(carouselItem: CarouselItem) {
        if (!this.carouselItems) {
            return;
        }

        this.editedCarouselItem = CloneHelper.deepCloneObject({ 
            ...carouselItem,
            image: {
                ...carouselItem.image,
                obj: undefined
            }
        });

        if (carouselItem.image.src) {
            const imageUrl = process.env.VUE_APP_API_URL + carouselItem.image.src;
            const imageObj = await getFileObjectByUrl(imageUrl, carouselItem.content);

            this.editedCarouselItem.image.obj = imageObj;
        }

        this.editedCarouselItemIndex = this.carouselItems.indexOf(carouselItem);
        this.formDialogMode = 'delete';
        this.showFormDialog = true;
    }

    /**
     * Updates the edited item in the data table
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private updateEditedCarouselItem(formDialogMode: string, carouselItemIndex: number, carouselItem: CarouselItem) {
        if (this.carouselItems) {
            if (formDialogMode == 'create') {
                this.carouselItems.push(carouselItem);
            }
            else if (formDialogMode == 'update') {
                Object.assign(this.carouselItems[carouselItemIndex], carouselItem);
            }
            else if (formDialogMode == 'delete') {
                this.carouselItems.splice(carouselItemIndex, 1);
            }

            this.showFormDialog = false;
        }
    }
}
